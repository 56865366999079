<template>
  <div class="homeworkList">
    <div class="text">
      <div class="topic">问题总结:</div>
      <div class="topText">{{ problem }}</div>
      <div class="topic">教师评语:</div>
      <div class="topText">{{ comment }}</div>
    </div>
    <div class="exBox">
      <div class="box">
        <el-table
          v-loading="loading"
          :data="homeworkList"
          :header-cell-style="{background:'#F2F4FF',color:'#333333'}"
          :cell-style="{color:' #999999'}"
          style="width: 100%;
                    background: #F2F4FF;
                    border:1px solid #EFEFEF"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            prop="jobNumber"
            align="center"
            label="作业序号"
            width="100"
          />
          <el-table-column
            prop="questionName"
            align="center"
            label="题目名称"
            :show-overflow-tooltip="true"
            width="150"
          />
          <el-table-column
            prop="questionType"
            label="题型"
            align="center"
            :formatter="typeFormat"
            width="150"
          />
          <el-table-column
            prop="type"
            label="类型"
            align="center"
            :formatter="categoryFormat"
            width="150"
          />
          <el-table-column
            prop="level"
            label="难度"
            align="center"
            :formatter="levelFormat"
            width="150"
          />
          <el-table-column
            prop="status"
            label="结果"
            align="center"
            :formatter="statusFormat"
            width="150"
          />
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="150"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="getDetail(scope.$index,scope)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <div style="text-align: right;" class="page">
            <el-pagination
              :current-page="currentPage"
              :page-size="pageSize"
              layout=" prev, pager, next"
              :total="total"
              @current-change="currentChange"
            />
          </div>
        </div>
      </div>
      <el-dialog
        title="作业题目"
        :visible.sync="submitable"
        width="40%"
      >
        <div class="dialog-qustion">
          第{{ this.currentQuestion.index }}题
          <span v-html="this.currentQuestion.questionInformation" />

        </div>

        <div v-if="this.currentQuestion.questionType==this.questionTypeFormat.select||this.currentQuestion.questionType==this.questionTypeFormat.vacancy" class="right-wrong">
          <span v-if="this.currentQuestion.results===1 " style="color: #52bc62">正确
          </span>
          <span v-else style="color: #f5183e">错误
          </span>
        </div>

        <div class="dialog-qustion">
          <div>学生答案内容：</div>
          <div style="padding: 10px">{{ this.currentQuestion.studentAnswer }}</div>
        </div>

        <div class="dialog-qustion">
          <div>正确答案内容：</div>
          <div style="padding: 10px" v-html="this.currentQuestion.answerInformation" />
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button class="downLoadAnswer" type="text" @click="downLoadAnswer">下载附件</el-button>
        </span>
      </el-dialog>
    </div>
    <indexBottom />
  </div>
</template>

<script>
import indexBottom from '../../components/indexBottom'
import { mapState } from 'vuex'
import { getFeedback, getStudentInfoList } from '../../api/course'

export default {
  name: 'HomeworkList',
  components: { indexBottom },
  data() {
    return {
      loading: false,
      problem: '',
      comment: '',
      // 查看题目弹框的显示
      submitable: false,
      homeworkList: [],
      questionDetail: '',
      total: 100,
      currentPage: 1,
      pageSize: 10,
      // 当前点击题目序号
      currentQuestionIndex: 1,
      currentQuestion: {
        index: 1,
        questionInformation: '',
        answerInformation: '',
        studentAnswer: '',
        questionType: 0,
        results: 0
      },
      publishId: '',
      questionTypeFormat: {},
      // 当前序号需要加上这些
      currentPn: 1

    }
  },

  created() {
    this.onload()
  },
  computed: {
    ...mapState({
      ea_question_type: state => state.dict.dictTree.ea_question_type['arr'],
      ea_question_category: state => state.dict.dictTree.ea_question_category['arr'],
      ea_question_difficulty: state => state.dict.dictTree.ea_question_difficulty['arr']
    })
  },
  methods: {
    onload() {
      this.formatTypeList()
      this.getHomeworkDetail()
    },
    // 详情
    getHomeworkDetail() {
      getStudentInfoList({ taskLogId: this.$route.query.id, pageSize: this.pageSize, pageNum: this.currentPage }).then(res => {
        this.total = res.total
        this.homeworkList = res.rows
        this.publishId = res.rows[0].classPublishId
        if (res.total !== 0) {
          getFeedback({ id: this.publishId }).then(res => {
            console.log(res)
            this.problem = res.data.taskFeedback
            this.comment = res.data.comment
          })
        }
      })
    },
    // 下载附件
    downLoadAnswer() {
      if (this.currentQuestion.answerUrls == '' || this.currentQuestion.answerUrls == null || this.currentQuestion.answerUrls == undefined) {
        this.$message.warning('该试题答案没有附件')
      } else {
        this.$download.name(this.currentQuestion.answerUrls)
      }
    },
    indexMethod(index) {
      return index + this.currentPn
    },
    // 页码改变
    currentChange(val) {
      this.loading = true
      this.currentPage = val
      this.currentPn = this.pageSize * (this.currentPage - 1) + 1
      this.onload()
      this.loading = false
    },

    // 格式化题型列表
    formatTypeList() {
      const obj = {}
      this.ea_question_type.forEach(item => {
        if (item.label === '选择题') {
          obj.select = item.value
        } else if (item.label === '填空题') {
          obj.vacancy = item.value
        } else if (item.label === '简答题') {
          obj.answer = item.value
        } else if (item.label === '编程题') {
          obj.program = item.value
        }
      })
      console.log(obj)
      this.questionTypeFormat = obj
    },
    typeFormat(row) {
      let name = ''
      this.ea_question_type.forEach(function(item) {
        if (row.questionType == item.value) {
          name = item.label
        }
      })
      return name
    },
    levelFormat(row) {
      let name = ''
      this.ea_question_difficulty.forEach(function(item) {
        if (row.level == item.value) {
          name = item.label
        }
      })
      return name
    },
    categoryFormat(row) {
      let name = ''
      this.ea_question_category.forEach(function(item) {
        if (row.type == item.value) {
          name = item.label
        }
      })
      return name
    },
    statusFormat(row) {
      let name = ''
      name = row.results === 0 ? '错误' : row.results === null ? '暂无' : '正确'
      return name
    },

    // 查看详情
    getDetail(scope, index) {
      console.log(scope)
      console.log(index)
      this.currentQuestion = this.homeworkList[scope]
      this.currentQuestion.index = scope + 1
      this.submitable = true
    }
  }
}
</script>

<style scoped lang="less">
.exBox{
  width: 1200px;
  min-height: 600px;
  margin: 0 auto 17px;
  background: #f8f8fc;
  .box{
    width: 1160px;
    margin: 0 auto 19px;
    padding-top: 19px;
    padding-bottom: 19px;
    ::v-deep.el-table{
      .el-table__empty-block{
        width: 100% !important;
        background: #fff;
        min-height: 600px;
      }
    }
  }
}
.pageBox{
  width: 1200px;
  margin-top: 20px;
  height: 40px;
  position: relative;
  .page{
    position: absolute;
    right: 40px;
    bottom: 10px;
    border: 1px solid #E8E8E8;
    width: auto;
  }
  ::v-deep.el-pagination{
    .el-pager{
      .number{
        color: #999999;
      }
      .active{
        color: #39A5EF;
      }
      .more{
        color: #999999;
      }
    }
  }
}

.text{
  width: 1200px;
  padding: 30px;
  background-color: #f8f8fc;
  margin: 17px auto 0;
  text-align: left;
  min-height: 115px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  .topic{
    font-size: 18px;
  }
  .topText{
    min-height: 70px;
    line-height: 2em;
    padding: 10px;
  }
}
.dialog-qustion{
  text-align: left;
  font-size: 18px;
  padding: 5px;
  margin-bottom: 10px;
}
.right-wrong{
  float: right;
  padding-right: 30px;
}
.downLoadAnswer{
  padding-right: 30px;
}
</style>
